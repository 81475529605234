import { styled } from 'Theme/stitches.config';

type PropType = {
  src?: string;
  alt: string;
  isUsed?: boolean;
  lazyLoad?: 'eager' | 'lazy';
  objectFit?: string;
};

function ProductImage({
  src,
  alt = '',
  isUsed,
  lazyLoad = 'eager',
  objectFit = 'cover',
}: PropType) {
  return (
    <Image
      src={src}
      alt={alt}
      isUsed={isUsed}
      loading={lazyLoad}
      css={{ objectFit: objectFit }}
    ></Image>
  );
}

const Image = styled('img', {
  objectFit: 'cover',
  w: '100%',
  h: '100%',
  filter: '$colors$imageFilter',
  variants: {
    isUsed: {
      true: {
        filter: 'none',
      },
      false: {
        filter: '$colors$imageFilter',
      },
    },
  },
});

export default ProductImage;
