import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function User({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle
        d="M11.9996 13.7144C14.3665 13.7144 16.2853 11.7956 16.2853 9.42866C16.2853 7.06172 14.3665 5.14294 11.9996 5.14294C9.63265 5.14294 7.71387 7.06172 7.71387 9.42866C7.71387 11.7956 9.63265 13.7144 11.9996 13.7144Z"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M4.67969 20.4C5.44467 19.1443 6.51982 18.1066 7.80176 17.3864C9.0837 16.6663 10.5293 16.2881 11.9997 16.2881C13.47 16.2881 14.9157 16.6663 16.1976 17.3864C17.4796 18.1066 18.5547 19.1443 19.3197 20.4"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M11.9998 23.1429C18.1538 23.1429 23.1426 18.1541 23.1426 12C23.1426 5.846 18.1538 0.857178 11.9998 0.857178C5.84576 0.857178 0.856934 5.846 0.856934 12C0.856934 18.1541 5.84576 23.1429 11.9998 23.1429Z"
        stroke={color}
        strokeRound
      />
    </BaseSvgStyle>
  );
}

export default User;
