import { styled } from '@stitches/react';
import { isSvgFile } from 'Shared/Common/Helpers';

type Props = {
  src: string;
  width?: number;
  alt?: string;
};

function SiteLogo({ src, width = 132, alt = 'Company logo' }: Props) {
  const widthStyles = isSvgFile(src)
    ? { width: `${width}px` }
    : { width: 'auto' };

  return src ? <Image src={src} alt={alt} css={{ ...widthStyles }} /> : null;
}

const Image = styled('img', {
  maxWidth: '100%',
  maxHeight: '48px',
});

export default SiteLogo;
