import { styled } from 'Theme/stitches.config';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import { Fragment } from 'react';
import ReactCarousel from '../Carousel/ReactCarousel';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
type PropTypes = {
  uspItems: string[];
};

function TopBanner({ uspItems }: PropTypes) {
  const isMobile = useMedia(mediaQueryTypes.bpMax960);

  const renderUspItem = (item: string) => {
    return (
      <BodyText size="s" tag="span" css={uspItemStyle}>
        {item}
      </BodyText>
    );
  };

  return (
    <>
      {uspItems?.length > 0 ? (
        <Root>
          <ContentContainer>
            {isMobile ? (
              uspItems.length === 1 ? (
                renderUspItem(uspItems[0])
              ) : (
                <ReactCarousel>
                  {uspItems.map((uspItem, index) => (
                    <Fragment key={index}>{renderUspItem(uspItem)}</Fragment>
                  ))}
                </ReactCarousel>
              )
            ) : (
              <DesktopWrapper alignment={uspItems.length}>
                {uspItems.map((uspItem, index) => (
                  <Fragment key={index}>{renderUspItem(uspItem)}</Fragment>
                ))}
              </DesktopWrapper>
            )}
          </ContentContainer>
        </Root>
      ) : null}
    </>
  );
}

const Root = styled('div', {
  w: '100%',
  overflowX: 'hidden',
  backgroundColor: '$surfaceVariant',
  position: 'relative',
  zIndex: '$zIndices$Header',
});

const DesktopWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  variants: {
    alignment: {
      '1': {
        justifyContent: 'center',
      },
    },
  },
});

const uspItemStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  h: '28px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  overflowX: 'auto',
  fontFamily: 'fontSemiBold',
  textTransform: 'uppercase',
  color: '$onSurface',
};

export default TopBanner;
