import ProductImage from 'DesignComponents/Atoms/OLD/Image/ProductImage';
import { styled } from 'Theme/stitches.config';
import SearchLinkResultModel from 'Models/Search/SearchLinkResultModel.interface';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import {
  ImageScalingTypes,
  getResizedImageFromEgmontCDN,
} from 'Shared/Common/ResizeImageEgmontCDN';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';

type PropTypes = {
  item: SearchLinkResultModel;
  imageScalingType?: ImageScalingTypes;
  onClick?: () => void;
};

function QuickSearchCard({
  item,
  onClick,
  imageScalingType = ImageScalingTypes.MINICART_MAGAZINE,
}: PropTypes) {
  const {
    image,
    secondaryImage,
    name,
    url,
    nowPrice,
    wasPrice,
    fromNoOfIssuesText,
    egmontNameOfAdditionalItems,
  } = item;

  const appSettingsData = useAppSettingsData();
  const { missingImage, highlightPremiumImages } =
    appSettingsData.imageSettings;
  const kexNavigate = useKexNavigate();

  const bigImage =
    highlightPremiumImages && secondaryImage ? secondaryImage : image;
  const smallImage =
    highlightPremiumImages && secondaryImage ? image : undefined;

  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (url) {
      if (event.target instanceof HTMLAnchorElement) {
        // Prevent the default click event for the anchor tag
        event.preventDefault();
      }
      onClick && onClick();
      kexNavigate(url);
    }
  };

  return (
    <CartItemContainer onClick={handleCardClick}>
      <ImageContainer>
        <ProductImage
          src={
            bigImage && bigImage.src
              ? getResizedImageFromEgmontCDN(bigImage.src, imageScalingType)
              : missingImage.src
          }
          alt={bigImage?.alt}
          objectFit="contain"
        />
        {smallImage && (
          <PremiumImage
            src={getResizedImageFromEgmontCDN(
              smallImage.src,
              ImageScalingTypes.MAGAZINE_CARD
            )}
            alt={smallImage.alt || ''}
          />
        )}
      </ImageContainer>

      <Details>
        <InteractableContentLink href={url} onClick={onClick}>
          <Title>{name}</Title>
          {egmontNameOfAdditionalItems && (
            <PremiumDescription>
              + {egmontNameOfAdditionalItems}
            </PremiumDescription>
          )}
        </InteractableContentLink>

        {(fromNoOfIssuesText || egmontNameOfAdditionalItems) && (
          <FromNoOfIssues>{fromNoOfIssuesText}</FromNoOfIssues>
        )}
        {nowPrice && (
          <Price>
            <NowPrice>
              {nowPrice?.priceAsString}{' '}
              <Currency>{nowPrice.currencySymbol}</Currency>
            </NowPrice>
            {!wasPrice.isZero && (
              <WasPrice>{wasPrice?.priceWithSymbol}</WasPrice>
            )}
          </Price>
        )}
      </Details>
    </CartItemContainer>
  );
}

const Title = styled('span', {
  fontFamily: 'fontSemiBold',
  fontSize: '$fontSize75',
  lineHeight: '$lh18',
  my: 0,
});

const FromNoOfIssues = styled('div', {
  fontSize: '$fontSize50',
  mt: 3,
});

const PremiumDescription = styled('div', {
  fontSize: '$fontSize50',
  fontFamily: 'fontMedium',
  pt: 1,
});
PremiumDescription;

const Price = styled('div', {
  display: 'flex',
  alignItems: 'baseline',
  mt: 1,
});

const NowPrice = styled('span', {
  fontSize: '$fontSize200',
  fontFamily: 'fontBold',
  whiteSpace: 'nowrap',
});

const WasPrice = styled('span', {
  fontSize: '$fontSize50',
  color: '$onSurfaceDisabled',
  textDecoration: 'line-through',
  ml: 2,
  whiteSpace: 'nowrap',
});

const Details = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  pt: 0,
});

const CartItemContainer = styled('div', {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  height: '100px',
  position: 'relative',
  color: '$onSurface',
  maxWidth: '40vw',

  '@bpMax720': {
    maxWidth: '100%',
  },

  variants: {
    isMiniCart: {
      true: {
        '&:not(:last-child)': {
          py: 5,
          borderBottomWidth: '1px',
          borderBottomColor: '$primary2',
        },
      },
    },
  },
});

const ImageContainer = styled('div', {
  position: 'relative',
  mr: 4,
  p: 2,
  height: '100px',
  width: '75px',
  minWidth: '75px',
  backgroundColor: '$decorationBackground',
  '@bpMin721': {
    height: '70px',
    width: '53px',
    minWidth: '53px',
  },
  '@bpMin961': {
    height: '100px',
    width: '75px',
    minWidth: '75px',
  },
});

const PremiumImage = styled('img', {
  position: 'absolute',
  b: '5px',
  r: '5px',
  height: '40%',
});

const Currency = styled('span', {
  fontSize: '$fontSize75',
});

export default QuickSearchCard;
