import { ReactNode } from 'react';
import Slider from 'react-slick';

type Props = {
  children?: ReactNode;
};

function ReactCarousel({ children }: Props) {
  const settings = {
    rtl: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };
  return <Slider {...settings}>{children}</Slider>;
}

export default ReactCarousel;
